import 'vanilla-cookieconsent/src/cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

/*
NOTES:

'CookieConsent' is the name of the plugin package used.
'CookieControl' is the name of our wrapping implementation (this class).
This naming difference is intentional, to keep clear where cookieConsent stops, and our code starts.

When editing permission settings here, you will also need to consider (and possibly amend) the
Ruby counterpart (cookie_control.rb).
*/

class CookieControl {
  constructor({
    cookieSettingsLinksSelector,
    googleAnalyticsKey,
    translations,
    revision,
    cookieExpiration,
    delay,
  }) {
    let pageViewRecorded = false;
    const cookieConsent = window.initCookieConsent();
    const t = translations.cookie_consent; // Concise version, an alias if you like.

    function loadGoogleAnalytics() {
      if (!googleAnalyticsKey) {
        CookieControl.log('Warning:  Google Analytics key NOT present.');
        return;
      }

      // Note - Google's script loads 'ga' into a global window.ga object, fyi.
      cookieConsent.loadScript('https://www.google-analytics.com/analytics.js', () => {
        // It's VERY IMPORTANT that the domain given when we create ga is NOT 'auto' !!
        // If it was to be 'auto' - the cookie domain would be too high level to be matched for
        // deletion by autoclear_cookies.  Needs to be the full domain name.
        window.ga('create', googleAnalyticsKey, window.location.hostname); // Read note above!
        CookieControl.log(`Google Analytics created, using key ${googleAnalyticsKey}`);
        if (!pageViewRecorded) {
          window.ga('send', 'pageview');
          pageViewRecorded = true;
          CookieControl.log('Google Analytics pageview recorded.');
        }
      });
    }

    function decodeHTML(html) {
      const txt = document.createElement('textarea');
      txt.innerHTML = html;
      return txt.value;
    }

    cookieConsent.run({
      autoclear_cookies: true,
      remove_cookie_tables: true,
      force_consent: true,
      hide_from_bots: true,
      use_rfc_cookie: true,
      cookie_expiration: cookieExpiration, // days
      delay, // ms
      revision, // default of 0
      onAccept() {
        CookieControl.log('You completed cookie preferences - whether that be accepted or rejected or something custom.');
        if (cookieConsent.allowedCategory('analytics')) {
          CookieControl.log('You said YES to analytics');
          loadGoogleAnalytics();
        } else {
          CookieControl.log('You said NO to analytics');
        }
      },
      onChange() {
        CookieControl.log('You just changed your preferences');
        if (cookieConsent.allowedCategory('analytics')) {
          CookieControl.log('You just changed your preferences and now said YES to analytics');
          loadGoogleAnalytics();
        } else {
          CookieControl.log('You just changed your preferences and now said NO to analytics');
        }
      },
      current_lang: 'en', // Cosmetic (in console).  We're passing in translations to same one lang.
      languages: {
        en: {
          consent_modal: {
            title: t.consent_modal.title,
            description: `${decodeHTML(t.consent_modal.description)} {{revision_message}}`,
            revision_message: decodeHTML(t.consent_modal.revision_message),
            primary_btn: {
              text: t.consent_modal.accept_all,
              role: 'accept_all', // 'accept_selected' or 'accept_all'
            },
            secondary_btn: {
              text: t.consent_modal.reject_all,
              role: 'accept_necessary', // 'settings' or 'accept_necessary'
            },
          },
          settings_modal: {
            title: t.settings_modal.title,
            save_settings_btn: t.settings_modal.save_settings_btn,
            accept_all_btn: t.settings_modal.accept_all_btn,
            reject_all_btn: t.settings_modal.reject_all_btn, // optional, [v.2.5.0 +]
            close_btn_label: t.settings_modal.close_btn_label,
            cookie_table_headers: [
              { col1: 'cookie name' }, // Need to keep this in, even though not displayed, so autoclear works.
            ],
            blocks: [
              {
                title: t.settings_modal.blocks.intro.title,
                description: decodeHTML(t.settings_modal.blocks.intro.description),
              },
              {
                title: t.settings_modal.blocks.necessary.title,
                description: t.settings_modal.blocks.necessary.description,
                toggle: {
                  value: 'necessary',
                  enabled: true,
                  readonly: true,
                },
                cookie_table: [
                  { col1: '_cms_session' },
                  { col1: 'user_credentials' },
                ],
              },
              {
                title: t.settings_modal.blocks.functional.title,
                description: t.settings_modal.blocks.functional.description,
                toggle: {
                  value: 'functional',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: 'CONSENT' },
                  { col1: 'YSC' },
                  { col1: '_abexps' },
                  { col1: '__pdst' },
                  { col1: 'vuid' },
                  { col1: 'player' },
                ],
              },
              {
                title: t.settings_modal.blocks.analytics.title,
                description: t.settings_modal.blocks.analytics.description,
                toggle: {
                  value: 'analytics',
                  enabled: false,
                  readonly: false,
                },
                cookie_table: [
                  { col1: '_ga' },
                  { col1: '_gat' },
                  { col1: '_gid' },
                ],
              },
            ],
          },
        },
      },
    });

    /*
    Note:  The cookieConsent settings modal might need to be opened before they've interacted
    with the cookieConsent at all (i.e. before onAccept has ever run), so need to place the
    cookie settings link code here (outside of onAccept).

    Note:  Setting up cookie setting link event listeners here, after cookieConsent has been
    initialised - so the initial consentModal has been rendered - so we can add an event listener
    onto a link in the cookieConsent consent modal description to open settings (if present).
    */

    if (cookieSettingsLinksSelector) {
      this.cookieSettingsLinks = document.querySelectorAll(cookieSettingsLinksSelector);
    }

    if (this.cookieSettingsLinks) {
      this.cookieSettingsLinks.forEach((link) => {
        link.addEventListener(
          'click',
          (e) => {
            e.preventDefault();
            cookieConsent.showSettings();
          },
        );
      });
    }

    // Add items to 'this' - so they may be accessed via this instance from elsewhere.
    // Haven't done this earlier, just to avoid a bunch of 'this.' prefixes in the above code.
    this.cookieConsent = cookieConsent;
    this.translations = translations;
  }

  videoCookiesAllowed() {
    return this.cookieConsent.allowedCategory('functional');
  }

  static log(...args) { /* eslint-disable-line no-unused-vars */
    // console.log(...args); /* eslint-disable-line no-console */
  }
}

export default CookieControl;
